import React, { useEffect, useState } from "react"
import Seo from "components/seo"
import Layout from "layout"
import Monogram from "components/monogram"
//import WorkGrid from "components/work-grid"
import { Link } from "gatsby"

const Error404Page = ({ location }) => {
  const [introSeen, setIntroSeen] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIntroSeen(true)
    }, 4000)
    return () => clearTimeout(timer)
  }, [])
  return (
    <Layout>
      <Seo title="Page Not Found" />

      <Monogram duration={3} isVisible={!introSeen} />
      <div>
        <div className="intro"></div>
        <div className="recent-work">
          <div className="container">
            <div className="text">
              <h2>Page not found.</h2>
              <div className="col addl-highlights">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/contact">Project Inquiry</Link>
                  </li>
                  <li>
                    <a href="https://twitter.com/campquiet">Twitter</a>
                  </li>
                  <li>
                    <a href="https://instagram.com/campquiet">Instagram</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Error404Page
